import { render, staticRenderFns } from "./new.view.vue?vue&type=template&id=47b7318a&scoped=true&"
import script from "./new.view.ts?vue&type=script&lang=ts&"
export * from "./new.view.ts?vue&type=script&lang=ts&"
import style0 from "./new.view.vue?vue&type=style&index=0&id=47b7318a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b7318a",
  null
  
)

export default component.exports